import React, { useState, useEffect } from 'react';
import './SliderAutomat.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import "./SliderProducts.css";

const formatPrice = (price) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};
const ProductSlider = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Fetch products from the API
  useEffect(() => {
    axios
      .get('https://quiagromaquinaria.com/apiiiwebsite/get_all_productsRaw.php')
      .then((response) => {
        setProducts(response.data.products);  // Assuming the data is in 'products'
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Show loading message while data is being fetched
  }

  const handleProductClick = (id) => {
    navigate(`/details/${id}`); // Redirect to /details/id
  };

  return (
    <div className="slider">
      <div className="slider-track slider-track23">
        {/* Render the products */}
        {products.map((product, index) => (
          <div
            className="slide slide2"
            key={`product-${index}`}
            onClick={() => handleProductClick(product.id)} // Handle click to redirect
            
         >
            <div className="product-image">
              <img src={'https://quiagromaquinaria.com/' + product.main_photo} alt={product.title} />
            </div>
            <div className="product-info">
              {/* Limit the title to 26 characters */}
              <h4>{product.title.length > 26 ? product.title.slice(0, 26) + ' ' : product.title} {formatPrice(product.price)}</h4>
            </div>
          </div>
        ))}
        {/* Duplicate the products to allow seamless scrolling */}
        {products.map((product, index) => (
          <div
            className="slide slide2"
            key={`product-duplicate-${index}`}
            onClick={() => handleProductClick(product.id)} // Handle click to redirect
          >
            <div className="product-image">
              <img src={'https://quiagromaquinaria.com/' + product.main_photo} alt={product.title} />
            </div>
            <div className="product-info">
              {/* Limit the title to 26 characters */}
              <h4>{product.title.length > 26 ? product.title.slice(0, 26) + ' ' : product.title} {formatPrice(product.price)} </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSlider;
