import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './PopupMenu.css'; // Custom CSS for mobile styling
import { useTranslation } from 'react-i18next';
const PopupMenu = ({ open, onClose }) => {
    const { t } = useTranslation(); // Use the `t` function to get translations
  return (
    <Dialog
      fullScreen // Make the dialog full screen
      open={open} // Control whether it's open
      onClose={onClose} // Handle close event
      PaperProps={{
        style: {
          backgroundColor: 'white', // Optional background color
          padding: '20px', // Optional padding
          color:'white'
        },
      }}
    >
      <DialogTitle>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" style={{float: 'right'}}>
          <CloseIcon  style={{color:'#27af0a', fontSize:'37px'}}/> {/* Quit button to close the popup */}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="menu-links">
          <Button component={Link} to="/" onClick={onClose}>
          {t('HomePage')} {/* Home */}
          </Button>
          <Button component={Link} to="/company" onClick={onClose}>
          {t('Company')} {/* Contact */}
          </Button>
          <Button component={Link} to="/services" onClick={onClose}>
          {t('Services')} {/* Contact */}
          </Button>
          <Button component={Link} to="/transport" onClick={onClose}>
          {t('Transport')} {/* Contact */}
          </Button>
          <Button component={Link} to="/export" onClick={onClose}>
          {t('Export')} {/* Contact */}
          </Button>
          <Button component={Link} to="/findus" onClick={onClose}>
          {t('WhereToFindUs')} {/* Contact */}
          </Button>
          <Button component={Link} to="/contact" onClick={onClose}>
          {t('ContactPage')} {/* Contact */}
          </Button>
          <Button component={Link} to="/about-us" onClick={onClose}>
          {t('AboutUsPage')} {/* About Us */}
          </Button>
          <Button component={Link} to="/inventory" onClick={onClose}>
          {t('machineryList')} {/* About Us */}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PopupMenu;
