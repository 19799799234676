import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';

const PhoneButton = ({ label, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{ 
        display: 'inline-flex', 
        alignItems: 'center', 
        cursor: 'pointer', 
        padding: '5px', 
        border: '1px solid #27af0a', // Optional border for visual distinction
        borderRadius: '5px',// Rounded corners
        marginRight: '25px',
        marginTop: '10px',
        marginLeft: '-10px',
        backgroundColor: ' !important',
        color: 'white',
        background: '#27af0a',
        justifyContent: 'center',
        
        
      }}
    >
      <PhoneIcon style={{ color: '#27af0a!important', fontSize: '26px', marginRight: '5px' }} />
      <span>{label}</span>
    </div>
    
  );
};

export default PhoneButton;
