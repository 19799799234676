// pages/Home.js
import React from 'react';
import Container from '@mui/material/Container';
import FullWidthSlider from '../components/slider';

// import HomePageLeft from '../components/HomePageLeft';
import HomePageRight from '../components/HomePageRight';
import ProductCards from '../components/ProductsHomePage';
import '../components/style/main.css';
import SliderAutomat from './SliderAutomat';
import SliderProducts from './SliderProducts';
import SearchInput from '../pages/SearchInput';

import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation(); // ✅ Correct placement inside the function body

  return (
    <Container maxWidth={false} style={{ padding: 0, marginTop: '-30px', background:'white' }}>
     
        
      
      <FullWidthSlider />
      <SearchInput />
      <SliderProducts />
      <div className="two-column-layout paddingTop10">
       
        
        <HomePageRight />
      </div>
     
      <div className="paddingTop10" style={{ marginLeft: '10px', marginRight: '10px' }}>
        <div
          className="titleOverP"
          style={{
            background: '#27af0a',
            marginBottom: '10px',
            fontWeight: 'bold',
            color: 'white',
            padding: '5px',
            paddingLeft: '15px',
            textAlign: 'left',
            textTransform: 'uppercase',
            marginTop: '30px',
          }}
        >
          {t('newvhicles')}
        </div>
        <div>
          <ProductCards />
        </div>
        <div>
          <SliderAutomat />
        </div>
      </div>
    </Container>
  );
};

export default Home;
