import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { useTranslation } from 'react-i18next';
import './SearchInput.css';

const SearchInput = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  // Handle change in input field
  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  // Handle search button click and redirect
  const handleSearch = () => {
    if (query.trim()) {
      // Redirect to /search page with the query as a URL parameter.
      // encodeURIComponent is used to safely encode the query string.
      navigate(`/search?query=${encodeURIComponent(query)}`);
    }
  };

  // Handle enter key press to trigger search
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-input">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={t('Search')}
      />
      <button onClick={handleSearch}>{t('Search')}</button>
    </div>
  );
};

export default SearchInput;
