import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './ProductsHomePage.css';
import { useTranslation } from 'react-i18next';
const ProductCards = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const formatPrice = (price) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://quiagromaquinaria.com/apiiiwebsite/get_product_for_homepage.php');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data.products); // Extract 'products' array from the API response
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={3} key={product.id} sx={{ width: '24.25%' }}>
          <div onClick={() => window.location.href = `/details/${product.id}`} style={{ cursor: 'pointer' }}>
            <Card sx={{ maxWidth: '100%' }}>
              <CardMedia
                component="img"
                height="140"
                image={'https://quiagromaquinaria.com/' + product.main_photo} // Assuming 'main_photo' is the key for the main product photo
                alt={product.title}
                sx={{ objectFit: 'fill', height: '250px' }}
              />
              <CardContent style={{ padding: '0px' }}>
                <Typography gutterBottom variant="h5" component="div" className='classForTitleforProductsHomepage'>
                  {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                  {product.price >= 10 ? (
                    <div>
                    {/* Original Price with discount strike-through */}
                    <h1
                      style={{
                        color: '#27af0a', // A lighter color to indicate the price is "old"
                        
                        marginRight: '5px',
                        marginTop: '0px'
                      }}
                    >
                       <span  style={{
                        color: '#27af0a',
                        marginRight: '5px',
                        textDecoration: 'line-through',
                        marginTop: '0px'
                      }}>  {formatPrice(product.price * 1.20)} </span> - 
                     
                      
                      <span  style={{
                        color: 'red',
                        marginRight: '5px',
                        textDecoration:'none',
                        marginTop: '0px'
                      }}> {formatPrice(product.price )} </span>
                    </h1>
                  
                  
                  </div>
                  
                    ) : (
                      <h1 style={{ color: '#27af0a', marginRight: '5px', marginTop: '0px' }}>
                        {t('askforPrice')} 
                      </h1>
                    )}
                    
                    {product.price >= 10 && (
                      <small style={{ color: '#27af0a', fontWeight: 'bold', marginTop: '-10px' }}>
                        {t('taxincluded')} 
                      </small>
                    )}
                  </div>
                  <div style={{ marginLeft: '10px', marginTop: '-15px' }}>
                  {t('Reference')} : <span style={{ fontWeight: 'bold', color: 'black' }}>{product.reference}</span>
                  </div>
                  <div style={{ marginLeft: '10px', marginBottom: '20px' }}>
                  {t('Year')} : <span style={{ fontWeight: 'bold', color: 'black' }}>{product.year}</span>
                  </div>
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductCards;
