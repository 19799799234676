import moment from 'moment'; // Import moment for date and time manipulation

// Define business hours for each day
const businessHours = {
  Monday: { open: "10:00", close: "17:00" },
  Tuesday: { open: "10:00", close: "17:00" },
  Wednesday: { open: "10:00", close: "17:00" },
  Thursday: { open: "10:00", close: "17:00" },
  Friday: { open: "10:00", close: "17:00" },
  Saturday: { open: null, close: null },
  Sunday: { open: null, close: null }, // Business is closed on Sundays
};

export const isBusinessOpen = () => {
  const now = moment(); // Current date and time
  const currentDay = now.format('dddd'); // Current day name (e.g., 'Monday')

  const hours = businessHours[currentDay]; // Business hours for the current day
  if (!hours || !hours.open || !hours.close) {
    // Business is closed today, so find the next open day
    const days = Object.keys(businessHours);
    let dayIndex = days.indexOf(currentDay);

    let nextOpenDay = null;
    let nextOpenTime = null;

    // Find the next day when the business is open
    for (let i = 1; i < days.length; i++) {
      const nextDayIndex = (dayIndex + i) % days.length;
      const nextDay = days[nextDayIndex];
      const nextHours = businessHours[nextDay];
      if (nextHours && nextHours.open) {
        nextOpenDay = nextDay;
        nextOpenTime = nextHours.open;
        break;
      }
    }

    return {
      isOpen: false,
      nextOpenDay: nextOpenDay,
      nextOpenTime: nextOpenTime,
      closingTime: null,
    };
  }

  const currentTime = now.format('HH:mm'); // Current time in HH:mm format
  const openTime = moment(hours.open, 'HH:mm');
  const closeTime = moment(hours.close, 'HH:mm');
  const isOpen = now.isBetween(openTime, closeTime, null, '[)'); // Check if within business hours

  if (isOpen) {
    return {
      isOpen: true,
      nextOpenDay: null,
      nextOpenTime: null,
      closingTime: closeTime.format('HH:mm'), // Return the closing time for today
    };
  }

  // If business is closed now but it's within the same day
  if (currentTime < hours.open) {
    return {
      isOpen: false,
      nextOpenDay: currentDay,
      nextOpenTime: hours.open,
      closingTime: null,
    };
  }

  // If current time is after today's closing time, find the next opening day
  const days = Object.keys(businessHours);
  let dayIndex = days.indexOf(currentDay);
  let nextOpenDay = null;
  let nextOpenTime = null;

  for (let i = 1; i < days.length; i++) {
    const nextDayIndex = (dayIndex + i) % days.length;
    const nextDay = days[nextDayIndex];
    const nextHours = businessHours[nextDay];
    if (nextHours && nextHours.open) {
      nextOpenDay = nextDay;
      nextOpenTime = nextHours.open;
      break;
    }
  }

  return {
    isOpen: false,
    nextOpenDay: nextOpenDay,
    nextOpenTime: nextOpenTime,
    closingTime: null,
  };
};
