import React from 'react';
import './SearchColumn.css';
import './ContactForm.css';
// Define the component to fetch and display data
import { useTranslation } from 'react-i18next';

import SearchColumn from '../components/SearchColumn';
import ContactForm from '../components/ContactForm';
import BuyMachinery from '../components/BuyMachinery';
const HomePageLeft = () => {
  const { t } = useTranslation();

  // Render the fetched data
  return (
    
    <div className="flex-column flex-left" >
        <div
            className="titleOverP"
            style={{
              background: '#27af0a', 
color: 'white',
              margin: '10px', 
              fontWeight: 'bold',
              padding: '5px', 
              paddingLeft: '15px', 
              textAlign: 'left', 
              textTransform: 'uppercase',
            }}>
            {t('Search')}
        </div>
      <SearchColumn />
   
      <div
            className="titleOverP"
            style={{
              background: '#27af0a', 
color: 'white',
              margin: '10px', 
              fontWeight: 'bold',
              padding: '5px', 
              paddingLeft: '15px', 
              textAlign: 'left', 
              textTransform: 'uppercase',
            }}>
            {t('ContactPage')}
        </div>
      <ContactForm />

      <div
            className="titleOverP"
            style={{
              background: '#27af0a', 
color: 'white',
              margin: '10px', 
              fontWeight: 'bold',
              padding: '5px', 
              paddingLeft: '15px', 
              textAlign: 'left', 
              textTransform: 'uppercase',
            }}>
            {t('WeBuyYourVehicle')}
        </div>
      <BuyMachinery />
    </div>
  );
};

export default HomePageLeft;





