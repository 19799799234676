import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert'; // To display error messages
import '../components/CategoryCards.css';

const CategoryCards = () => {
  const [categories, setCategories] = useState([]); // State to hold fetched data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State for error handling

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://quiagromaquinaria.com/apiiiwebsite/search.php'); // Fetch from backend

        if (!response.ok) {
          throw new Error('Failed to fetch data'); // Handle unsuccessful responses
        }

        const data = await response.json(); // Parse the JSON response
        if (!Array.isArray(data.categories)) {
          throw new Error('Data format is incorrect'); // Validate the data structure
        }

        // Ensure "See all stock" is prioritized
        const sortedCategories = data.categories.sort((a, b) => {
          if (a.category === 'See all stock') return -1; // "See all stock" is prioritized
          if (b.category === 'See all stock') return 1;
          return 0;
        });

        setCategories(sortedCategories); // Store categories in state
        setLoading(false); // Update loading status
      } catch (err) {
        setError(err.message); // Set error message in case of failure
        setLoading(false); // Stop loading
      }
    };

    fetchCategories(); // Fetch data when the component mounts
  }, []); // Empty dependency array to ensure it runs only once

  if (loading) {
    return <CircularProgress />; // Display a loading spinner during data fetch
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>; // Display an error message if fetching fails
  }

  const handleCardClick = (category) => {
    navigate(`/category/${category.category.toLowerCase()}`); // Convert category to lowercase and redirect
  };

  return (
    <Grid container spacing={3}> {/* Grid layout for arranging cards */}
      {categories.map((category, index) => ( // Map over categories to create cards
        <Grid item xs={12} sm={2.4} md={2.4} key={index} className='transformonHoverToBlack'>
        <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
            onClick={() => handleCardClick(category)}
          > {/* Set consistent height and flex direction */}
            {category.photo_name && ( // Only display CardMedia if a photo is available
              <CardMedia
                component="img" // Render as an image
                height="140" // Set height for the image
                image={`iconite_categorii_2/${category.photo_name}`} // Path or URL to the image
                alt={`${category.category} image`} // Alt text for accessibility
                sx={{ objectFit: 'contain' }} // Ensures the image fills the area without distortion
              />
            )}
            <CardContent className="contentCardFromGreyToBlack" sx={{ flex: 1, textAlign: 'center', textTransform: 'lowercase', background: 'black', color: 'white', marginTop: '5px' }}> {/* Centered content */}
              <Typography variant="h6" className="contentCardFromGreyToBlack"
                style={{ background: 'black', color: 'white', textTransform: 'uppercase' }} >
                {category.category} ({category.total_count}) {/* Category name and count */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryCards;
