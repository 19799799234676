// src/components/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import './headerCss.css';



const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language); // Default to current language
 

  const changeLanguage = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <FormControl variant="standard" style={{background:'white'}} className='whiteForced buttonFormOutline'  >
      
      <Select value={language} onChange={changeLanguage} className='whiteForced' >
        
      <MenuItem value="es">Español</MenuItem>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="de">Deutsch</MenuItem>
      <MenuItem value="it">Italiano</MenuItem>
      <MenuItem value="hu">Magyar</MenuItem>
      <MenuItem value="pl">Polski</MenuItem>
      <MenuItem value="fr">Français</MenuItem>
      <MenuItem value="ro">Română</MenuItem>
      <MenuItem value="da">Dansk</MenuItem>
      <MenuItem value="sk">Slovenčina</MenuItem>
      <MenuItem value="sv">Svenska</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
