import React from 'react';
import Slider from 'react-slick'; // Import react-slick
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import additional theme styles
import './FullWidthSlider.css'; // Import your custom CSS for animations


import BusinessStatus from './BusinessStatus'; 
// Sample data for the slider
const sliderItems = [
  {
    id: 1,
    title: 'Maquinaria',
    description: 'Maquinaria',
    image: 'https://static.ecoglobal21.com/slider/Dise%C3%B1o%20sin%20t%C3%ADtulo%20(13).png',
  },
  {
    id: 2,
    title: 'Maquinaria',
    description: 'Maquinaria',
    image: 'https://static.ecoglobal21.com/slider/Dise%C3%B1o%20sin%20t%C3%ADtulo%20(15).png',
  },
  {
    id: 3,
    title: 'Maquinaria',
    description: 'Maquinaria',
    image: 'https://static.ecoglobal21.com/slider/Dise%C3%B1o%20sin%20t%C3%ADtulo%20(14).png',
  },
  
  {
    id: 4,
    title: 'Maquinaria',
    description: 'Maquinaria',
    image: 'https://static.ecoglobal21.com/slider/Dise%C3%B1o%20sin%20t%C3%ADtulo%20(10).png',
  },
  
  {
    id: 5,
    title: 'Maquinaria',
    description: 'Maquinaria',
    image: 'https://static.ecoglobal21.com/slider/Dise%C3%B1o%20sin%20t%C3%ADtulo%20(8).png',
  },
];






// Slider settings for animations and other options
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  fade: true,
  pauseOnHover: false,
};

const FullWidthSlider = () => (
  <div style={{ width: '100%' }} className='sliderOnMobile'>
    <Slider {...sliderSettings}>
      {sliderItems.map((item) => (
        <div key={item.id} className="slide-animation"> {/* Apply the custom animation */}
          <img
            src={item.image}
            alt={item.title}
            style={{ width: '100%', height: 'auto' }}
          />
         
        </div>
      ))}
    </Slider>
    <BusinessStatus className="openCss"/> {/* Display the business status */}
  </div>
);

export default FullWidthSlider;
