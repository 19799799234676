import React from 'react';
import './SliderAutomat.css';

// Import all logos individually
import logo1 from './assets/1.png';
import logo2 from './assets/2.png';
import logo4 from './assets/4.png';
import logo5 from './assets/5.png';
import logo6 from './assets/6.png';
import logo7 from './assets/7.png';
import logo8 from './assets/8.png';
import logo9 from './assets/9.png';
import logo10 from './assets/10.png';
import logo11 from './assets/11.png';
import logo12 from './assets/12.png';
import logo13 from './assets/13.png';
import logo14 from './assets/14.png';
import logo15 from './assets/15.png';
import logo16 from './assets/16.png';
import logo17 from './assets/17.png';
import logo18 from './assets/18.png';
import logo19 from './assets/19.png';
import logo20 from './assets/20.png';
import logo21 from './assets/21.png';
import logo22 from './assets/22.png';
import logo23 from './assets/23.png';
import logo24 from './assets/24.png';
import logo25 from './assets/25.png';
import logo26 from './assets/26.png';

const logos = [
  { src: logo1, alt: 'Brand 1' },
  { src: logo2, alt: 'Brand 2' },
  // Make sure you add logo3 if needed (it was skipped in your example)
  { src: logo4, alt: 'Brand 4' },
  { src: logo5, alt: 'Brand 5' },
  { src: logo6, alt: 'Brand 6' },
  { src: logo7, alt: 'Brand 7' },
  { src: logo8, alt: 'Brand 8' },
  { src: logo9, alt: 'Brand 9' },
  { src: logo10, alt: 'Brand 10' },
  { src: logo11, alt: 'Brand 11' },
  { src: logo12, alt: 'Brand 12' },
  { src: logo13, alt: 'Brand 13' },
  { src: logo14, alt: 'Brand 14' },
  { src: logo15, alt: 'Brand 15' },
  { src: logo16, alt: 'Brand 16' },
  { src: logo17, alt: 'Brand 17' },
  { src: logo18, alt: 'Brand 18' },
  { src: logo19, alt: 'Brand 19' },
  { src: logo20, alt: 'Brand 20' },
  { src: logo21, alt: 'Brand 21' },
  { src: logo22, alt: 'Brand 22' },
  { src: logo23, alt: 'Brand 23' },
  { src: logo24, alt: 'Brand 24' },
  { src: logo25, alt: 'Brand 25' },
  { src: logo26, alt: 'Brand 26' },
];

const LogoSlider = () => {
  return (
    <div className="slider">
      <div className="slider-track">
        {/* Render the logos once */}
        {logos.map((logo, index) => (
          <div className="slide slibrand" style={{marginTop:'0px!important', marginBottom:'0px!important'}} key={`logo-${index}`}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
        {/* Duplicate the logos to allow seamless scrolling */}
        {logos.map((logo, index) => (
          <div className="slide slibrand"  style={{marginTop:'0px!important', marginBottom:'0px!important'}} key={`logo-duplicate-${index}`}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoSlider;
